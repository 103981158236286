import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";

function ConvertShape({selectedTag, buttonTags, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible('ConvertToShapeVisibility');
  const [deleteLayer, setDeleteLayer] = useVisible('deleteLayer');

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-sharp fa-regular fa-object-subtract"></i>
        </div>
        <div className="button-card__title">Convert To Shape</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
        <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="delete-layer-shape"
            checked={deleteLayer}
            onChange={() => setDeleteLayer(!deleteLayer)}
            className="hidden-checkbox"
          />
          <label htmlFor="delete-layer-shape">
            <span className="custom-checkbox"></span>
            <span className="label-text">Delete Layer</span>
          </label>
        </div>
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick(
                "aiConvert",
                {deleteLayer:deleteLayer}
              )
            }
            className="render-group__button"
          >
            <i class="fa-light fa-shapes"></i>
            <span className="render-group__button-text">Convert Shape</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConvertShape;
