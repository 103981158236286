import useVisible from "../../hooks/useVisible.jsx";

function SlicedScaleGroup({selectedTag, buttonTags, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible('SlicedScaleGroupVisibility');

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
          className={`button-card resolution-toggle ${isVisible ? 'active' : ''}`}
      >
          <div className="button-card__icon">
          <i class="fa-sharp fa-solid fa-scalpel-line-dashed"></i>
        <div />
      </div>
      <div className="button-card__title">Sliced Scale</div>
      {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? '' : 'hidden'}`}>
      <div
        onClick={() => handleClick("addSlicedScale", 1)}
        className="resolution-tools__button"
      >
        <i class="fa-light fa-left-from-line"></i>
        <span>Left</span>
      </div>
      <div
        onClick={() => handleClick("addSlicedScale", 2)}
        className="resolution-tools__button"
      >
        <i class="fa-light fa-left-right"></i>
        <span>Center</span>
      </div>
      <div
        onClick={() => handleClick("addSlicedScale", 3)}
        className="resolution-tools__button"
      >
        <i class="fa-light fa-left-from-line fa-flip-horizontal"></i>
        <span>Right</span>
      </div>
    </div>
    </>
  );
}

export default SlicedScaleGroup;
