import useVisible from "../../hooks/useVisible.jsx";

function ShapeToImage({ selectedTag, buttonTags, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("ShapeToImageVisibility");
  const [optimize, setOptimize] = useVisible("ShapeToImageOptimize");

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-image"></i>
          <div />
        </div>
        <div className="button-card__title">Shape To Image</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="shape-image-optimize"
              checked={optimize}
              onChange={() => setOptimize(!optimize)}
              className="hidden-checkbox"
            />
            <label htmlFor="shape-image-optimize">
              <span className="custom-checkbox"></span>
              <span className="label-text">Optimize Image</span>
            </label>
          </div>
        </div>
        <div className="videobolt-tools">
          <div
            onClick={() =>
              handleClick(
                "convertShapeToImage",
                JSON.stringify({
                  type: "layer",
                  optimize: optimize,
                  notification: true,
                }),
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-light fa-layer-group"></i>
            <span>Selected Layers</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "convertShapeToImage",
                JSON.stringify({
                  type: "comp",
                  optimize: optimize,
                  notification: true,
                }),
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-image-polaroid"></i>
            <span>Active Comp</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "convertShapeToImage",
                JSON.stringify({
                  type: "selected comp",
                  optimize: optimize,
                  notification: true,
                }),
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-images"></i>
            <span>Selected Comp</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "convertShapeToImage",
                JSON.stringify({
                  type: "project",
                  optimize: optimize,
                  notification: true,
                }),
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-photo-film"></i>
            <span>Project</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShapeToImage;
