import handleClick from "../../utils/handleClick.js";
import useVisible from "../../hooks/useVisible.jsx";

function RenderImportFootage({ selectedTag, buttonTags, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("RenderImportFootageVisibility");
  const [checkedOption, setCheckedOption] = useVisible("RenderImportFootageChecked");

  const handleCheckboxChange = (option) => {
    if (checkedOption === option) {
      setCheckedOption(false); // Снимаем выбор, если текущий чекбокс уже выбран
    } else {
      setCheckedOption(option);
    }
  };

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i className="fa-sharp fa-regular fa-camera-movie"></i>
        </div>
        <div className="button-card__title">Render & Import Footage</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          {/* Checkbox for Random */}
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="comp"
              checked={checkedOption === "comp"}
              onChange={() => handleCheckboxChange("comp")}
              className="hidden-checkbox"
            />
            <label htmlFor="comp">
              <span className="custom-checkbox"></span>
              <span className="label-text">Active Comp</span>
            </label>
          </div>

          {/* Checkbox for Step */}
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="selected comp"
              checked={checkedOption === "selected comp"}
              onChange={() => handleCheckboxChange("selected comp")}
              className="hidden-checkbox"
            />
            <label htmlFor="selected comp">
              <span className="custom-checkbox"></span>
              <span className="label-text">Selected Comp</span>
            </label>
          </div>

          {/* Checkbox for Step */}
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="project"
              checked={checkedOption === "project"}
              onChange={() => handleCheckboxChange("project")}
              className="hidden-checkbox"
            />
            <label htmlFor="project">
              <span className="custom-checkbox"></span>
              <span className="label-text">Project</span>
            </label>
          </div>
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() => {
              handleClick(
                "ae2ppRenderFootage",
                {type: checkedOption}
              );
            }}
            className="render-group__button"
          >
            <i class="fa-light fa-video-arrow-up-right"></i>
            <span className="render-group__button-text">Render Footage</span>
          </div>

          <div
            onClick={() => {
              handleClick(
                "ae2ppImportFootage",
                {type: checkedOption}
              );
            }}
            className="render-group__button"
          >
            <i class="fa-light fa-video-arrow-down-left"></i>
            <span className="render-group__button-text">Import Footage</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default RenderImportFootage;
