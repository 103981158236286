import handleClick from "../../utils/handleClick.js";
import useVisible from "../../hooks/useVisible.jsx";
import "./ResolutionTools.css";

function ResolutionTools({selectedTag, buttonTags, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible('ResolutionToolsVisibility');

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
          className={`button-card resolution-toggle ${isVisible ? 'active' : ''}`}
      >
          <div className="button-card__icon">
          <i class="fa-sharp fa-solid fa-high-definition"></i>
        <div />
      </div>
      <div className="button-card__title">Resolution Tools</div>
      {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? '' : 'hidden'}`}>
      <div
        onClick={() => handleClick("changeAspectRatio", [1080, 1080])}
        className="resolution-tools__button"
      >
        Square <span>1080x1080</span>
      </div>
      <div
        onClick={() => handleClick("changeAspectRatio", [1080, 1350])}
        className="resolution-tools__button"
      >
        Post <span>1080x1350</span>
      </div>
      <div
        onClick={() => handleClick("changeAspectRatio", [1080, 1920])}
        className="resolution-tools__button"
      >
        Vertical <span>1080x1920</span>
      </div>
      <div
        onClick={() => handleClick("changeAspectRatio", [1920, 1080])}
        className="resolution-tools__button"
      >
        Full HD <span>1920x1080</span>
      </div>
      <div
        onClick={() => handleClick("changeAspectRatio", [2560, 1080])}
        className="resolution-tools__button"
      >
        UltraWide <span>2560x1080</span>
      </div>
      <div
        onClick={() => handleClick("changeAspectRatio", [2560, 1440])}
        className="resolution-tools__button"
      >
        2K <span>2560x1440</span>
      </div>
      <div
        onClick={() => handleClick("changeAspectRatio", [3840, 2160])}
        className="resolution-tools__button"
      >
        4K <span>3840x2160</span>
      </div>
      <div
        onClick={() => handleClick("changeAspectRatio", [])}
        className="resolution-tools__button"
      >
        Other <span>User Size</span>
      </div>

      <div
        onClick={() => handleClick("lockComp", {comment:'lock', type:'layer', unlock: false})}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-lock"></i>
        <span>Lock Size</span>
      </div>
      <div
        onClick={() => handleClick("lockComp", {comment:'lock resize', type:'layer', unlock: false})}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-hashtag-lock"></i>
        <span>Lock & Resize</span>
      </div>
      <div
        onClick={() => handleClick("lockMask", {comment:'lock mask'})}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-lock"></i>
        <span>Lock Mask</span>
      </div>
      <div
        onClick={() => handleClick("lockComp", {comment:'', type:'layer', unlock: true})}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-lock-open"></i>
        <span>Unlock Layer</span>
      </div>
      <div
        onClick={() => handleClick("lockComp", {comment:'', type:'project', unlock: true})}
        className="resolution-tools__button"
      >
        <i class="fa-sharp fa-light fa-broom-wide"></i>
        <span>Clean Project</span>
      </div>
      <div
        onClick={() => handleClick("responseShape", false)}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-code"></i><span>Expression</span>
      </div>
      <div
        onClick={() => handleClick("createPlaceholder", false)}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-image"></i><span>Placeholder</span>
      </div>
      <div
        onClick={() => handleClick("importVideoPlaceholder", false)}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-film"></i><span>Import Video Placeholder</span>
      </div>
    </div>
    </>
  );
}

export default ResolutionTools;
