import handleClick from "../../utils/handleClick.js";
import useVisible from "../../hooks/useVisible.jsx";

function NormalizeTrackMatte({selectedTag, buttonTags, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible('NormalizeTrackMatteVisibility');

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-sharp fa-solid fa-down-to-line"></i>
          <div />
        </div>
        <div className="button-card__title">Normalize Track Matte</div>
        {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? "" : "hidden"}`}>
        <div
          onClick={() => handleClick("ppNormalizeTrackMatte", {type:"layer"})}
          className="resolution-tools__button"
        >
          <i class="fa-light fa-layer-group"></i>
          <span>Selected Layers</span>
        </div>
        <div
          onClick={() => handleClick("ppNormalizeTrackMatte", {type:"comp"})}
          className="resolution-tools__button"
        >
          <i class="fa-solid fa-image-polaroid"></i>
          <span>Active Comp</span>
        </div>
        <div
          onClick={() => handleClick("ppNormalizeTrackMatte", {type:"selected comp"})}
          className="resolution-tools__button"
        >
          <i class="fa-solid fa-images"></i>
          <span>Selected Comp</span>
        </div>
        <div
          onClick={() => handleClick("ppNormalizeTrackMatte", {type:"project"})}
          className="resolution-tools__button"
        >
          <i class="fa-regular fa-photo-film"></i>
          <span>Project</span>
        </div>
      </div>
    </>
  );
}

export default NormalizeTrackMatte;
