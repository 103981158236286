import useVisible from "../../hooks/useVisible.jsx";
import React, { useState, useEffect } from "react";
import handleClick from "../../utils/handleClick.js";

function MaskToComp({ selectedTag, buttonTags, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("MaskToCompVisibility");
  const colors = {
    red: [1, 0, 0],
    orange: [1, 0.5, 0],
    yellow: [1, 1, 0],
    green: [0, 1, 0],
    aqua: [0, 1, 1],
    sandstone: [0.8, 0.7, 0.5],
};

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-regular fa-square-dashed-circle-plus"></i>
          <div />
        </div>
        <div className="button-card__title">Mask to Comp</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="set-button">
          <div
            onClick={() => handleClick("maskToCompCreateComp")}
            className={`render-group__button`}
          >
              <>
                <i class="fa-sharp fa-regular fa-square-plus"></i>
                <span className="render-group__button-text">
                  Create Comp
                </span>
              </>
          </div>
        </div>

        <div className="videobolt-tools">
          <div
            onClick={() =>
              handleClick(
                "maskToCompCreateMask",
                {color:colors.orange,
                  name:'Text',
                  fullSize: false
                }
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-text"></i>
            <span>Text Mask</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "maskToCompCreateMask",
                {color:colors.green,
                  name:'Media',
                  fullSize: false
                }
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-image"></i>
            <span>Media Mask</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "maskToCompCreateMask",
                {color:colors.aqua,
                  name:'Element',
                  fullSize: false
                }
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-triangle"></i>
            <span>Element Mask</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "maskToCompCreateMask",
                {color:colors.sandstone,
                  name:'Background',
                  fullSize: true
                }
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-rectangle-wide"></i>
            <span>Background Mask</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default MaskToComp;
