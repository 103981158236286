const hexToRgbArray = (hex) => {
  // Удаляем #, если он присутствует
  hex = hex.replace("#", "");

  // Преобразуем hex в RGB
  const r = parseInt(hex.substring(0, 2), 16) / 255;
  const g = parseInt(hex.substring(2, 4), 16) / 255;
  const b = parseInt(hex.substring(4, 6), 16) / 255;

  return [r, g, b];
};

const rgbArrayToHex = (rgbArray) => {
  return '#' + rgbArray.map(value => {
    let hex = Math.round(value * 255).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }).join('');
};

function getRandomHexColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export { hexToRgbArray, rgbArrayToHex, getRandomHexColor };
