import useVisible from "../../hooks/useVisible.jsx";

function ReviewTools({selectedTag, buttonTags, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible('ReviewToolsVisibility');

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-solid fa-magnifying-glass"></i>
          <div />
        </div>
        <div className="button-card__title">Review Tools</div>
        {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? "" : "hidden"}`}>
      <div
          onClick={() => handleClick("universalizeExpression", JSON.stringify({type:'project', notification: true}))}
          className="resolution-tools__button"
        >
          <i class="fa-sharp fa-regular fa-circle-check"></i>
          <span>Universalize Expression</span>
        </div>
        <div
          onClick={() => handleClick("revToolsTestControls", false)}
          className="resolution-tools__button"
        >
          <i class="fa-regular fa-sliders"></i>
          <span>Test Controls</span>
        </div>
        <div
          onClick={() => handleClick("revToolsTestColor", false)}
          className="resolution-tools__button"
        >
          <i class="fa-sharp fa-regular fa-fill-drip"></i>
          <span>Test Colors</span>
        </div>
        <div
          onClick={() => handleClick("revToolsTestText", false)}
          className="resolution-tools__button"
        >
          <i class="fa-sharp fa-regular fa-message-text"></i>
          <span>Test Text</span>
        </div>
      </div>
    </>
  );
}

export default ReviewTools;
