import React, { useState, useEffect } from "react";
import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";

function TransitionPreview({
  onPopupStateChange,
  selectedTag,
  buttonTag,
  generateButtonTags,
}) {
  const [isVisible, setIsVisible] = useVisible("TransitionPreviewVisibility");

  useEffect(() => {
    if (onPopupStateChange) {
      onPopupStateChange(isVisible);
    }
  }, [isVisible]);

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-sharp fa-solid fa-bolt"></i>
        </div>
        <div className="button-card__title">Transition Preview</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >

        <div className="videobolt-tools">
          <div
            onClick={() => handleClick('autoPreviewTransition', {type:'overlap'})}
            className="resolution-tools__button"
          >
            <i class="fa-light fa-circles-overlap"></i>
            <span>Overlap</span>
          </div>
          <div
            onClick={() => handleClick('autoPreviewTransition', {type:'join'})}
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-regular fa-arrow-right-to-bracket"></i>
            <span>Join</span>
          </div>
          <div
            onClick={() => handleClick('autoPreviewTransition', {type:'adjustment'})}
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-location-pin"></i>
            <span>Adjustment</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default TransitionPreview;
