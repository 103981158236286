import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";

function DisableAnimatorGroup({selectedTag, buttonTags, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible('DisableAnimatorGroupVisibility');

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
          onClick={() => setIsVisible(!isVisible)}
          className={`button-card resolution-toggle ${isVisible ? 'active' : ''}`}
      >
          <div className="button-card__icon">
          <i class="fa-light fa-eye-slash"></i>
        <div />
      </div>
      <div className="button-card__title">Disable Animators</div>
      {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? '' : 'hidden'}`}>

      <div
        onClick={() => handleClick("ppDisableTextAnimator", true)}
        className="resolution-tools__button"
      >
        <i class="fa-light fa-eye-slash"></i><span>Disable</span>
      </div>
      <div
        onClick={() => handleClick("ppEnabledTextAnimator", true)}
        className="resolution-tools__button"
      >
        <i class="fa-sharp fa-light fa-eye"></i><span>Enabled</span>
      </div>
    </div>
    </>
  );
}

export default DisableAnimatorGroup;
