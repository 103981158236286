import React, { useState, useContext } from "react";
import mainApi from "../../utils/api.js";
import {hexToRgbArray, rgbArrayToHex, getRandomHexColor} from "../../utils/colors.js";
import CurrentUserContext from "../../contexts/CurrentUserContext.jsx";
import Modal from "../Modal/Modal";
import { v4 as uuidv4 } from "uuid";
import "./Tags.css";

function Tags({ currentTag, onTagChange, tags, setTags }) {
  const currentUser = useContext(CurrentUserContext);
  const [showModal, setShowModal] = useState(false);
  const [editableTags, setEditableTags] = useState(tags);

  const handleTagClick = (id) => {
    if (currentTag === id) {
      onTagChange(null);
    } else {
      onTagChange(id);
    }
  };

  const handleOpenModal = () => {
    setEditableTags(tags); // Обновляем редактируемые теги при открытии модального окна
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDeleteTag = (id) => {
    const updatedTags = editableTags.map((tag) =>
      tag.id === id ? { ...tag, hidden: true } : tag
    );
    setEditableTags(updatedTags);
  };

  const handleNameChange = (id, newName) => {
    const updatedTags = editableTags.map((tag) =>
      tag.id === id ? { ...tag, name: newName } : tag
    );
    setEditableTags(updatedTags);
  };

  const handleAddNewTag = () => {
    const newTag = {
      id: uuidv4(),
      name: "",
      color: getRandomHexColor(), // Белый цвет по умолчанию
    };
    setEditableTags([...editableTags, newTag]);
  };

  const handleSaveTags = () => {
    const aeTags = editableTags.filter((tag) => !tag.hidden);
    const tagsWithoutId = aeTags.map(({ _id, ...rest }) => rest);
    localStorage.setItem("tags", JSON.stringify(tagsWithoutId));
    setTags(tagsWithoutId);
    mainApi
      .updateUser(currentUser._id, {
        tags: {
          aeTags: tagsWithoutId,
        },
      })
      .then((res) => {
        console.log('Update user tags')
      })
      .catch((error) => {
        console.error("Failed to update tags for user:", currentUser._id, error);
      });
    setShowModal(false);
  };

  const handleColorPicker = (id, color) => {
    // Конвертируем hex цвет в массив RGB от 0 до 1
    const getColor = hexToRgbArray(color);

    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(
      "run($.ae.colorPicker(" + JSON.stringify(getColor) + "));",
      function (data) {
        if (data) {
          // Преобразуем строку в массив чисел
          data = data.split(",").map(Number);

          // Преобразуем массив RGB от 0 до 1 обратно в hex
          const newColor = rgbArrayToHex(data);

          const updatedTags = editableTags.map((tag) =>
            tag.id === id ? { ...tag, color: newColor } : tag
          );
          setEditableTags(updatedTags);
        }
      }
    );
  };

  return (
    <div className="tags-container">
      <div className="tags-container__tags">
      {tags
  .filter((tag) => !tag.hidden)
  .map((tag) => (
    <div
      key={tag.id}
      className={`tags-container__tag ${
        currentTag === tag.id ? "active" : ""
      }`}
      onClick={() => handleTagClick(tag.id, tag.color)}
      style={{ backgroundColor: tag.color }}
      title={tag.name} // Показывает имя тега при наведении
    ></div>
  ))}
        <div className="tags-container__edit-tags" onClick={handleOpenModal}>
          <i className="fa-sharp fa-solid fa-pen-to-square"></i>
        </div>
      </div>

      <Modal isOpen={showModal} onClose={handleCloseModal}>
        <ul className="tags-container__tag-list">
          {editableTags
            .filter((tag) => !tag.hidden)
            .map((tag) => (
              <li key={tag.id} className="tags-container__tag-list-item">
                <div className="tags-container__main">
                <span
                  className="tags-container__tag-color"
                  style={{ backgroundColor: tag.color }}
                  onClick={() => handleColorPicker(tag.id, tag.color)}
                ></span>
                  <input
                    type="text"
                    value={tag.name}
                    onChange={(e) => handleNameChange(tag.id, e.target.value)}
                    className="tags-container__tag-input rename__input"
                  />
                </div>
                <div
                  className="tags-container__delete-button"
                  onClick={() => handleDeleteTag(tag.id)}
                >
                  <i className="fa-regular fa-trash"></i>
                </div>
              </li>
            ))}
        </ul>

        {/* Кнопка для добавления нового тега */}
        <div className="tags-container__add-new-tag" onClick={handleAddNewTag}>
          + Tag
        </div>

        {/* Кнопки для сохранения или отмены */}
        <div className="tags-container__buttons">
          <div className="tags-container__button" onClick={handleSaveTags}>
            Save
          </div>
          <div className="tags-container__button" onClick={handleCloseModal}>
            Cancel
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Tags;
