import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";

function BakePropertyPremiere({selectedTag, buttonTags, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible('BakePropertyPremiereVisibility');

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
          className={`button-card resolution-toggle ${isVisible ? 'active' : ''}`}
      >
          <div className="button-card__icon">
          <i class="fa-regular fa-wave-sine"></i>
        <div />
      </div>
      <div className="button-card__title">Bake Property</div>
      {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? '' : 'hidden'}`}>

      <div
        onClick={() => handleClick("ppBakeProperty", {notification:true, bakeProperty:'position'})}
        className="resolution-tools__button"
      >
        <i class="fa-light fa-arrows-up-down-left-right"></i>
        <span>Position</span>
      </div>
      <div
        onClick={() => handleClick("ppBakeProperty", {notification:true, bakeProperty:'ds position'})}
        className="resolution-tools__button"
      >
        <i class="fa-light fa-arrows-up-down-left-right"></i>
        <span>Separated Position</span>
      </div>
      <div
        onClick={() => handleClick("ppBakeProperty", {notification:true, bakeProperty:'scale'})}
        className="resolution-tools__button"
      >
        <i class="fa-light fa-arrows-maximize"></i>
        <span>Scale</span>
      </div>
      <div
        onClick={() => handleClick("ppBakeProperty", {notification:true, bakeProperty:'rotation'})}
        className="resolution-tools__button"
      >
        <i class="fa-light fa-rotate-right"></i>
        <span>Rotation</span>
      </div>
      <div
        onClick={() => handleClick("ppBakeProperty", {notification:true, bakeProperty:'opacity'})}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-circle-half-stroke"></i>
        <span>Opacity</span>
      </div>
      <div
        onClick={() => handleClick("ppBakeProperty", {notification:true, bakeProperty:'all'})}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-bars"></i>
        <span>All</span>
      </div>
    </div>
    </>
  );
}

export default BakePropertyPremiere;
