import React, { useState, useEffect } from "react";
import useVisible from "../../hooks/useVisible.jsx";
import "./SmartPreview.css";

function SmartPreview({
  onPopupStateChange,
  selectedTag,
  buttonTags,
  generateButtonTags,
}) {
  const [isVisible, setIsVisible] = useVisible("SmartPreviewVisibility");
  const [loader, setLoader] = useVisible("SmartPreviewLoader");
  const [placeholders, setPlaceholders] = useVisible(
    "SmartPreviewPlaceholders"
  );
  const [random, setRandom] = useVisible("SmartPreviewRandom");
  const [watermark, setMaWatermark] = useVisible("MAWatermark");
  const [preview, setPreview] = useVisible("MAPreview");
  const [format, setFormat] = useState(localStorage.getItem("smartPreviewSaveFrame") || "png");
  //const [text, setText] = useState(localStorage.getItem("SmartPreviewText") || "");

  // useEffect(() => {
  //   localStorage.setItem("SmartPreviewText", text);
  // }, [text]);

  useEffect(() => {
    if (onPopupStateChange) {
      onPopupStateChange(isVisible);
    }
  }, [isVisible]);

  useEffect(() => {
    localStorage.setItem("smartPreviewSaveFrame", format);
  }, [format]); // Эффект отслеживает изменения format
  //const [padding, setPadding] = useState(localStorage.getItem("AutoCropGroupPadding") || "");

  // useEffect(() => {
  //   localStorage.setItem("AutoCropGroupPadding", padding);
  // }, [padding]);

  const toggleFormat = () => {
    setFormat((currentFormat) => (currentFormat === "png" ? "jpeg" : "png"));
  };

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {
      if (script === "aeSaveFrame") {
        if (result) {
          // Парсим строку результата в массив путей к файлам
          const filePaths = JSON.parse(result);
          filePaths.forEach(async (filePath) => {
            // Чтение файла в формате base64
            let readResult = window.cep.fs.readFile(
              filePath,
              window.cep.encoding.Base64
            );
            if (readResult.err !== 0) {
              alert("Ошибка чтения файла: " + readResult.err);
              return; // Прерывание текущей итерации, если файл не может быть прочитан
            }

            const base64Data = readResult.data;
            const img = new Image();
            img.onload = async () => {
              const canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;

              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);

              // Конвертация в JPEG и обработка асинхронно
              canvas.toBlob(async (blob) => {
                const reader = new FileReader();
                reader.onloadend = async () => {
                  const jpegDataUrl = reader.result;
                  const base64ImageContent = jpegDataUrl.split(",")[1];

                  const newFilePath = filePath.replace(".png", ".jpeg");

                  // Запись JPEG файла
                  let writeResult = window.cep.fs.writeFile(
                    newFilePath,
                    base64ImageContent,
                    window.cep.encoding.Base64
                  );
                  if (writeResult.err === 0) {
                    // Файл успешно сохранен, можно удалить исходный файл
                    let deleteResult = window.cep.fs.deleteFile(filePath);
                    if (deleteResult.err !== 0) {
                      alert("Ошибка удаления файла: " + deleteResult.err);
                    }
                  } else {
                    alert("Ошибка записи файла: " + writeResult.err);
                  }
                };
                reader.readAsDataURL(blob);
              }, "image/jpeg");
            };

            img.onerror = () => {
              alert("Ошибка при загрузке изображения");
            };

            img.src = `data:image/png;base64,${base64Data}`;
          });
        }
      }
    });
  };

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-clapperboard"></i>
        </div>
        <div className="button-card__title">Smart Preview</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          {/* <input
            className="rename__input shape__input"
            type="number"
            value={padding}
            onChange={(e) => setPadding(e.target.value)}
            placeholder="Padding"
          /> */}
          <input
            type="checkbox"
            id="loader-smart-preview"
            checked={loader}
            onChange={() => {
              setLoader(!loader);
            }}
            className="hidden-checkbox"
          />
          <label htmlFor="loader-smart-preview">
            <span className="custom-checkbox"></span>
            <span className="label-text">Loader</span>
          </label>
          <input
            type="checkbox"
            id="placeholder-smart-preview"
            checked={placeholders}
            onChange={() => {
              setPlaceholders(!placeholders);
            }}
            className="hidden-checkbox"
          />
          <label htmlFor="placeholder-smart-preview">
            <span className="custom-checkbox"></span>
            <span className="label-text">Placeholders</span>
          </label>
          <input
            type="checkbox"
            id="random-smart-preview"
            checked={random}
            onChange={() => {
              setRandom(!random);
            }}
            className="hidden-checkbox"
          />
          <label htmlFor="random-smart-preview">
            <span className="custom-checkbox"></span>
            <span className="label-text">Random</span>
          </label>
        </div>

        {/* <div className="rename__input-group">
          <input
            className="rename__input"
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Marker Name"
          />
        </div> */}

        <div className="videobolt-tools">
          <div
            onClick={() =>
              handleClick(
                "aeSmartPreview",
                JSON.stringify({
                  loader: loader,
                  placeholders: placeholders,
                  random: random,
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-layer-plus"></i>
            <span>Create Preview</span>
          </div>
        </div>
        <div className="render-group__checkbox-group mg-top">
          <input
            type="checkbox"
            id="MA Watermark"
            checked={watermark}
            onChange={() => {
              setMaWatermark(!watermark);
            }}
            className="hidden-checkbox"
          />
          <label htmlFor="MA Watermark">
            <span className="custom-checkbox"></span>
            <span className="label-text">MA Watermark</span>
          </label>
          <input
            type="checkbox"
            id="MA Preview"
            checked={preview}
            onChange={() => {
              setPreview(!preview);
            }}
            className="hidden-checkbox"
          />
          <label htmlFor="MA Preview">
            <span className="custom-checkbox"></span>
            <span className="label-text">MA Preview</span>
          </label>
          <div className="collect-files-format" onClick={toggleFormat}>
            {format}
          </div>
        </div>
        <div className="videobolt-tools">
          <div
            onClick={() => handleClick("aeRenderPreview", JSON.stringify({watermark: watermark, preview: preview}))}
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-film"></i>
            <span>Render Preview</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "aeSaveFrame",
                JSON.stringify({
                  type: "current",
                  format:format
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-image"></i>
            <span>Save Frame</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default SmartPreview;
