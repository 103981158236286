import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";

function CreatePlaceholder({
  selectedTag,
  buttonTags,
  generateButtonTags,
  category,
}) {
  const [isVisible, setIsVisible] = useVisible("ResolutionToolsVisibility");
  let script;
  let name = "Create Drop Zone";

  if (category === "mg") {
    script = "mogrtPlaceholder";
    name = "Create Placeholder";
  }

  if (category === "pp") {
    script = "ppFillPlaceholder";
    name = "Fill Placeholder";
  }

  if (category === "am" || category === "dr") {
    script = "motionDropZone";
  }

  if (category === "gt") {
    script = "psPlaceholder";
  }

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-image"></i>
          <div />
        </div>
        <div className="button-card__title">{name}</div>
        {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? "" : "hidden"}`}>
        <div
          onClick={() =>
            handleClick(script, { auto: true, notification: true })
          }
          className="resolution-tools__button"
        >
          <i class="fa-solid fa-bolt"></i>
          <span>Auto</span>
        </div>
        <div
          onClick={() =>
            handleClick(script, { auto: false, notification: true })
          }
          className="resolution-tools__button"
        >
          <i class="fa-solid fa-images"></i>
          <span>Selected Comps</span>
        </div>
      </div>
    </>
  );
}

export default CreatePlaceholder;
