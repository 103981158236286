import useVisible from "../../hooks/useVisible.jsx";
import ppText from "../../utils/buttons/ppText";

function ExportPremiereGroup({ selectedTag, buttonTags, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("ExportPremiereGroupVisibility");
  const [bakeExpression, setBakeExpression] = useVisible(
    "bakePremiereExpression",
  );
  const [bakeScale, setBakeScale] = useVisible("bakePremiereScale");
  const [bakeParent, setBakeParent] = useVisible("bakePremiereParent");
  const [exportWhiteColorText, setWhiteColorText] = useVisible(
    "ExportWhiteColorText",
  );

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {
      if (result && result === "true") {
        ppText(true, exportWhiteColorText);
      }
    });
  };

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-square-p"></i>
        </div>
        <div className="button-card__title">Export Premiere</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="bakeExpression"
              checked={bakeExpression}
              onChange={() => setBakeExpression(!bakeExpression)}
              className="hidden-checkbox"
            />
            <label htmlFor="bakeExpression">
              <span className="custom-checkbox"></span>
              <span className="label-text">Bake Expression</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="bakeParent"
              checked={bakeParent}
              onChange={() => setBakeParent(!bakeParent)}
              className="hidden-checkbox"
            />
            <label htmlFor="bakeParent">
              <span className="custom-checkbox"></span>
              <span className="label-text">Bake Parent</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="bakeScale"
              checked={bakeScale}
              onChange={() => setBakeScale(!bakeScale)}
              className="hidden-checkbox"
            />
            <label htmlFor="bakeScale">
              <span className="custom-checkbox"></span>
              <span className="label-text">Bake Scale</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="export-white-color-text"
              checked={exportWhiteColorText}
              onChange={() => setWhiteColorText(!exportWhiteColorText)}
              className="hidden-checkbox"
            />
            <label htmlFor="export-white-color-text">
              <span className="custom-checkbox"></span>
              <span className="label-text">White Color Text</span>
            </label>
          </div>
        </div>
        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick(
                "ae2Premiere",
                JSON.stringify({
                  bakeExpression: bakeExpression,
                  bakeParent: bakeParent,
                  bakeScale: bakeScale,
                }),
              )
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-square-p"></i>
            <span className="render-group__button-text">Export</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExportPremiereGroup;
