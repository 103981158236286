import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";

function AddControl({selectedTag, buttonTags, generateButtonTags, category}) {
  const [isVisible, setIsVisible] = useVisible(category === 'mg' ? 'AddControlMogrt' : 'AddControlMotion');

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
          className={`button-card resolution-toggle ${isVisible ? 'active' : ''}`}
      >
          <div className="button-card__icon">
          <i class="fa-regular fa-gamepad"></i>
        <div />
      </div>
      <div className="button-card__title">Add Control</div>
      {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? '' : 'hidden'}`}>

      <div
        onClick={() => handleClick("mogrtControl", {name: 'control', clean: false})}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-plus"></i>
        <span>Add Control</span>
      </div>
      <div
        onClick={() => handleClick("mogrtControl", {name: 'ignore', clean: false})}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-minus"></i>
        <span>Ignore Control</span>
      </div>
      <div
        onClick={() => handleClick("mogrtControl", {name: '', clean: true})}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-trash"></i>
        <span>Delete Control</span>
      </div>
    </div>
    </>
  );
}

export default AddControl;
