import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";

function SplitTextLayer({selectedTag, buttonTags, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible('SplitTextLayerVisibility');
  const [animation, setAnimation] = useVisible('animationTextLayer');

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-solid fa-scissors"></i>
        </div>
        <div className="button-card__title">Split Text</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
        <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="animation-text-layer"
            checked={animation}
            onChange={() => setAnimation(!animation)}
            className="hidden-checkbox"
          />
          <label htmlFor="animation-text-layer">
            <span className="custom-checkbox"></span>
            <span className="label-text">Animation</span>
          </label>
        </div>
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick(
                "splitText",
                {
                  type: "letter",
                  animation: animation,
                }
              )
            }
            className="render-group__button"
          >
            <i class="fa-sharp fa-solid fa-font"></i>
            <span className="render-group__button-text">Letters</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "splitText",
                {
                  type: "word",
                  animation: animation,
                }
              )
            }
            className="render-group__button"
          >
            <i class="fa-solid fa-text-size"></i>
            <span className="render-group__button-text">Words</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "splitText",
                {animation: animation,}
              )
            }
            className="render-group__button"
          >
            <i class="fa-sharp fa-solid fa-align-justify"></i>
            <span className="render-group__button-text">Lines</span>
          </div>
          </div>
      </div>
    </>
  );
}

export default SplitTextLayer;
