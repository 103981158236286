import React, { useState, useEffect } from "react";
import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";

function AutoCropGroup({ selectedTag, buttonTags, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("AutoCropGroupVisibility");
  const [time, setTime] = useVisible("AutoCropGroupTime");
  const [padding, setPadding] = useState(localStorage.getItem("AutoCropGroupPadding") || "");

  useEffect(() => {
    localStorage.setItem("AutoCropGroupPadding", padding);
  }, [padding]);

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-solid fa-crop-simple"></i>
        </div>
        <div className="button-card__title">Auto Crop</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <input
            className="rename__input shape__input"
            type="number"
            value={padding}
            onChange={(e) => setPadding(e.target.value)}
            placeholder="Padding"
          />
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="time"
              checked={time}
              onChange={() => {
                setTime(!time);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="time">
              <span className="custom-checkbox"></span>
              <span className="label-text">Animation</span>
            </label>
          </div>
        </div>

        <div className="videobolt-tools">
        <div
          onClick={() =>
            handleClick(
              "autoCrop",
              {
                type: "layer",
                time: time,
                padding: padding || 0,
              }
            )
          }
          className="resolution-tools__button"
        >
          <i class="fa-light fa-layer-group"></i>
          <span>Selected Layers</span>
        </div>
        <div
          onClick={() =>
            handleClick(
              "autoCrop",
              {
                type: "comp",
                time: time,
                padding: padding || 0,
              }
            )
          }
          className="resolution-tools__button"
        >
          <i class="fa-solid fa-image-polaroid"></i>
          <span>Active Comp</span>
        </div>
        <div
          onClick={() =>
            handleClick(
              "autoCrop",
              {
                type: "selected comp",
                time: time,
                padding: padding || 0,
              }
            )
          }
          className="resolution-tools__button"
        >
          <i class="fa-solid fa-images"></i>
          <span>Selected Comp</span>
        </div>
        </div>
      </div>
    </>
  );
}

export default AutoCropGroup;
