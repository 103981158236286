import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";

function Collapse({selectedTag, buttonTags, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible('CollapseVisibility');

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-solid fa-compress"></i>
          <div />
        </div>
        <div className="button-card__title">Collapse</div>
        {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? "" : "hidden"}`}>
        <div
          onClick={() => handleClick("collapseOn", {type:"layer", collapse: true, undo:true})}
          className="resolution-tools__button"
        >
          <i class="fa-sharp fa-regular fa-layer-plus"></i>
          <span>Layers Enabled Collapse</span>
        </div>
        <div
          onClick={() => handleClick("collapseOn", {type:"layer", collapse: false, undo:true})}
          className="resolution-tools__button"
        >
          <i class="fa-sharp fa-regular fa-layer-minus"></i>
          <span>Layers Disable Collapse</span>
        </div>
        <div
          onClick={() => handleClick("collapseOn", {type:"project", collapse: true, undo:true})}
          className="resolution-tools__button"
        >
          <i class="fa-regular fa-square-e"></i>
          <span>Text Enabled Collapse</span>
        </div>
        <div
          onClick={() => handleClick("collapseOn", {type:"project", collapse: false, undo:true})}
          className="resolution-tools__button"
        >
          <i class="fa-regular fa-square-d"></i>
          <span>Text Disable Collapse</span>
        </div>
      </div>
    </>
  );
}

export default Collapse;
