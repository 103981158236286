import React, { useEffect, useState, useRef } from "react";
import useVisible from "../../hooks/useVisible.jsx";
import "./AutoBeat.css";
import handleClick from "../../utils/handleClick.js";

function AutoBeat({onPopupStateChange, selectedTag, buttonTags, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible("AutoBeatVisibility");
  const [beat, seatBeat] = useState(() => Number(localStorage.getItem('auto-beat')) || 50);
  const [minStep, setMinStep] = useState(() => Number(localStorage.getItem('auto-beat-step')) || 15);
  const [marker, setMarker] = useVisible("autoBeatMarker");
  const [textLayer, setTextLayer] = useVisible("autoBeatTextLayer");
  const [textCaps, setTextCaps] = useVisible("autoBeatTextCaps");
  const [removePunctuation, setRemovePunctuation] = useVisible("autoBeatRemovePunctuation");
  const [mediaPlaceholder, setMediaPlaceholder] = useVisible("autoBeatMediaPlaceholder");
  const [text, setText] = useState(localStorage.getItem("autoBeatText") || "");
  const textareaRef = useRef(null);

  useEffect(() => {
    localStorage.setItem('auto-beat', beat);
    localStorage.setItem('auto-beat-step', minStep);
  }, [beat, minStep]);

  useEffect(() => {
    localStorage.setItem("autoBeatText", text);
  }, [text]);

  useEffect(() => {
    if (onPopupStateChange) {
      onPopupStateChange(isVisible);
    }
  }, [isVisible]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleChange = (e) => {
    setText(e.target.value);
    adjustTextareaHeight();
  };

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-regular fa-music"></i>
        </div>
        <div className="button-card__title">Auto Beat</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
        <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="auto-beat-marker"
              checked={marker}
              onChange={() => {
                setMarker(!marker);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="auto-beat-marker">
              <span className="custom-checkbox"></span>
              <span className="label-text">Add Markers</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="auto-beat-text"
              checked={textLayer}
              onChange={() => {
                setTextLayer(!textLayer);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="auto-beat-text">
              <span className="custom-checkbox"></span>
              <span className="label-text">Add Text Layer</span>
            </label>
          </div>
          <div className={`render-group__checkbox ${!textLayer ? "hidden" : ""}`}>
            <input
              type="checkbox"
              id="auto-beat-caps"
              checked={textCaps}
              onChange={() => {
                setTextCaps(!textCaps);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="auto-beat-caps">
              <span className="custom-checkbox"></span>
              <span className="label-text">Text Caps</span>
            </label>
          </div>
          <div className={`render-group__checkbox ${!textLayer ? "hidden" : ""}`}>
            <input
              type="checkbox"
              id="auto-beat-remove-punctuation"
              checked={removePunctuation}
              onChange={() => {
                setRemovePunctuation(!removePunctuation);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="auto-beat-remove-punctuation">
              <span className="custom-checkbox"></span>
              <span className="label-text">Remove Punctuation</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="auto-beat-placeholder"
              checked={mediaPlaceholder}
              onChange={() => {
                setMediaPlaceholder(!mediaPlaceholder);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="auto-beat-placeholder">
              <span className="custom-checkbox"></span>
              <span className="label-text">Add Media Placeholder</span>
            </label>
          </div>
        </div>

      <div className="easeKey-sliderContainer">
      <span className="auto__beat_span">Beat</span>
        <input
          type="range"
          min="0"
          max="100"
          step="0.1"
          value={beat}
          onChange={(e) => seatBeat(Math.round(e.target.value))}
        />
        <span className="easeKey-value">{Math.round(beat)}</span>
      </div>

      <div className="easeKey-sliderContainer">
      <span className="auto__beat_span">Min Step</span>
        <input
          type="range"
          min="0"
          max="100"
          step="0.1"
          value={minStep}
          onChange={(e) => setMinStep(Math.round(e.target.value))}
        />
        <span className="easeKey-value">{Math.round(minStep)}</span>
      </div>

        <div className={`rename__input-group auto__beat ${!textLayer ? "hidden" : ""}`}>
          <textarea
            className="rename__input"
            value={text}
            onChange={handleChange}
            placeholder="Type you text ..."
            ref={textareaRef}
            style={{ overflow: "hidden", resize: "none" }}
          />
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick(
                "autoBeat",
                {
                  string: text,
                  beat: beat,
                  minStep: minStep,
                  markers: marker,
                  textLayers: textLayer,
                  placeholders: mediaPlaceholder,
                  textCaps: textCaps,
                  removePunctuation : removePunctuation,
                }
              )
            }
            className={`render-group__button ${marker || textLayer || mediaPlaceholder ? "" : "disabled"}`}
          >
            <i class="fa-regular fa-play"></i>
            <span className="render-group__button-text">Create</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default AutoBeat;
