import React, { useEffect, useState } from "react";
import useVisible from "../../hooks/useVisible.jsx";
import "./AnchorAlignNull.css";
import handleClick from "../../utils/handleClick.js";

function AnchorAlignNull() {
  const [selectedScript, setSelectedScript] = useVisible('$.ae.moveAnchor');

  useEffect(() => {
    // Проверка, установлено ли уже значение для selectedScript
    if (!selectedScript) {
      // Если selectedScript не установлен, установить начальное значение
      const initialValue = '$.ae.moveAnchor'; // Установите подходящее начальное значение
      setSelectedScript(initialValue);
    }
  }, [selectedScript]);

  const iconsConfig = [
    { arg: "top-left", iconClass: "fa-light fa-arrow-up-left" },
    { arg: "top", iconClass: "fa-light fa-arrow-up" },
    { arg: "top-right", iconClass: "fa-light fa-arrow-up-right" },

    { arg: "left", iconClass: "fa-light fa-arrow-left" },
    { arg: "center", iconClass: "fa-sharp fa-regular fa-plus" },
    { arg: "right", iconClass: "fa-light fa-arrow-right" },

    {
      arg: "bottom-left",
      iconClass: "fa-light fa-arrow-up-left fa-flip-vertical",
    },
    { arg: "bottom", iconClass: "fa-light fa-arrow-up fa-flip-vertical" },
    {
      arg: "bottom-right",
      iconClass: "fa-light fa-arrow-up-right fa-flip-vertical",
    },
  ];

  return (
    <div className="anchorAlignContainer">
      <div className="anchorAlignContainer__selectors">
        <div
          className={`anchorAlignContainer__selector ${
            selectedScript === "$.ae.moveAnchor" ? "active" : ""
          }`}
          onClick={() => setSelectedScript("$.ae.moveAnchor")}
        >
          <i className="fa-solid fa-anchor"></i>
        </div>
        <div
          className={`anchorAlignContainer__selector ${
            selectedScript === "alignTo" ? "active" : ""
          }`}
          onClick={() => setSelectedScript("alignTo")}
        >
          <i className="fa-sharp fa-solid fa-objects-align-center-vertical"></i>
        </div>
        <div
          className={`anchorAlignContainer__selector ${
            selectedScript === "newNull" ? "active" : ""
          }`}
          onClick={() => setSelectedScript("newNull")}
        >
          <i className="fa-sharp fa-solid fa-layer-plus"></i>
        </div>
      </div>

      <div className="anchorAlignContainer__iconsGrid">
        {iconsConfig.map((icon, index) => (
          <div
            key={index}
            className="anchorAlignContainer__icon"
            onClick={() => handleClick(selectedScript, icon.arg)}
          >
            <i className={icon.iconClass}></i>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AnchorAlignNull;
