import React, { useState, useEffect } from "react";
import useVisible from "../../hooks/useVisible.jsx";

function TitlesMogrt({ selectedTag, buttonTags, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("TitlesMogrtVisibility");
  const [duplicate, setDuplicate] = useState(localStorage.getItem("TitlesMogrtDuplicate") || "");


  useEffect(() => {
    localStorage.setItem("TitlesMogrtDuplicate", duplicate);
  }, [duplicate]);

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-solid fa-text-size"></i>
        </div>
        <div className="button-card__title">Titles Mogrt</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <input
            className="rename__input shape__input"
            type="number"
            value={duplicate}
            onChange={(e) => setDuplicate(e.target.value)}
            placeholder="Copy"
          />
        </div>

        <div className="transition-group__tools">
          <div
            onClick={() =>
              handleClick(
                "titlesMogrt",
                JSON.stringify({
                  type: "create",
                  duplicate: duplicate || 1,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-plus"></i>
            <span className="render-group__button-text">Create</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "titlesMogrt",
                JSON.stringify({
                  type: "link",
                  duplicate: 0,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-link"></i>
            <span className="render-group__button-text">Link</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default TitlesMogrt;
