import {
  getGoogleFontLinks,
  getAdobeFontsLinks
} from "../linksFonts.js";

function getFonts(pdf, fonts, format, type, site) {
  window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");

  if (fonts) {
    window.jsx.evalScript(`run(getFontTemplate("${type}"));`, function (result) {
      if (result) {
        const data = JSON.parse(result);
        if ((!site || site === 'google')) {
          getGoogleFontLinks(data, format, site)
        }
        if (site === 'adobe') {
          getAdobeFontsLinks(data, format, site)
        }
      }
    });
  } else {
    window.jsx.evalScript("run(MAfolder(" + JSON.stringify({
      fonts: false,
      helpFile: pdf
    }) + "));")
  }
}

export default getFonts ;
