import React from 'react';
import './BackgroundColor.css';
import handleClick from "../../utils/handleClick.js";

const BackgroundColor = () => {

  const script = "bgColor";

  return (
    <div className="background__color-container">
      <div className="background__color-square black" onClick={() => handleClick(script, [0,0,0])}></div>
      <div className="background__color-square gray" onClick={() => handleClick(script, [0.5,0.5,0.5])}></div>
      <div className="background__color-square white" onClick={() => handleClick(script, [1,1,1])}></div>
    </div>
  );
};

export default BackgroundColor;
