import useVisible from "../../hooks/useVisible.jsx";

function ResponsiveText({selectedTag, buttonTags, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible('PinsGroupVisibility');

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-sharp fa-regular fa-text-width"></i>
          <div />
        </div>
        <div className="button-card__title">Responsive Text</div>
        {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? "" : "hidden"}`}>
        <div
          onClick={() => handleClick("responsiveText", JSON.stringify({type:'layer'}))}
          className="resolution-tools__button"
        >
          <i class="fa-light fa-layer-group"></i>
          <span>Selected Layers</span>
        </div>
        <div
          onClick={() => handleClick("responsiveText", JSON.stringify({type:'project'}))}
          className="resolution-tools__button"
        >
          <i class="fa-regular fa-photo-film"></i>
          <span>Project</span>
        </div>
        <div
            onClick={() => handleClick("vbCleanText", false)}
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-solid fa-text-slash"></i>
            <span>Text Clean</span>
          </div>
      </div>
    </>
  );
};

export default ResponsiveText;
