import React, { useState, useEffect, useContext } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import "./EmailVerifier.css";

const EmailVerifier = ({ setEmailVerifier, handleUpdateUser, handleSignOut }) => {
  const currentUser = useContext(CurrentUserContext);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [displayText, setDisplayText] = useState("Email");
  const [code, setCode] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false); // Управление состоянием отправки кода
  const [timer, setTimer] = useState(0); // Состояние для таймера

  // Инициализация и обновление на основе currentUser
  useEffect(() => {
    const email = currentUser?.email;
    if (email) {
      setDisplayText(email);
    } else {
      setInputValue("");
      setDisplayText("Email");
    }
  }, [currentUser]);

  // Запуск таймера при отправке кода
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0 && isCodeSent) {
      setIsCodeSent(false); // Отображаем кнопку после завершения таймера
    }
    return () => clearInterval(interval);
  }, [timer, isCodeSent]);

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleSendLink = () => {
    const newCode = Math.floor(1000 + Math.random() * 9000);
    handleUpdateUser(currentUser._id, { emailVerifier: { code: newCode } });
    setCode(Number(newCode));
    setIsCodeSent(true); // Скрываем кнопку
    setTimer(60); // Устанавливаем таймер на 60 секунд
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    console.log(value);
    console.log(code);

    if (Number(value) === Number(code)) {
      handleUpdateUser(currentUser._id, { emailVerifier: { verify: true } });
      setEmailVerifier(true)
    }
  };

  return (
    <div className="telegram-link-container">
      <span className="telegram-link__span">Confirm email: {displayText}</span>
      <input
        autoFocus
        type="number"
        value={inputValue}
        className="telegram-link-input"
        onChange={handleInputChange}
        onBlur={handleBlur}
        placeholder="Enter Code"
      />
      {!isCodeSent ? (
        <div className="email-send__code" onClick={handleSendLink}>
          Send code / Отправить код
        </div>
      ) : (
        <div className="email-send__code-disabled">
          Resend in {timer} seconds
        </div>
      )}
      <div className="user-profile__logout email__logout" onClick={handleSignOut}>
        Sign out / Выйти
      </div>
    </div>
  );
};

export default EmailVerifier;
