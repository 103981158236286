import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";

function MaskGroup({selectedTag, buttonTags, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible('MaskGroupVisibility');
  const [deleteLayer, setDeleteLayer] = useVisible('deleteMaskLayer');

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-light fa-draw-polygon"></i>
        </div>
        <div className="button-card__title">Mask Convert</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
        <div className="render-group__checkbox">
          <input
            type="checkbox"
            id="delete-layer-mask"
            checked={deleteLayer}
            onChange={() => setDeleteLayer(!deleteLayer)}
            className="hidden-checkbox"
          />
          <label htmlFor="delete-layer-mask">
            <span className="custom-checkbox"></span>
            <span className="label-text">Delete Layer</span>
          </label>
        </div>
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick(
                "convertAutoTrace",
                {deleteLayer:deleteLayer}
              )
            }
            className="render-group__button"
          >
            <i class="fa-sharp fa-light fa-masks-theater"></i>
            <span className="render-group__button-text">Convert Auto-Trace</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "maskToShape",
                {deleteLayer:deleteLayer}
              )
            }
            className="render-group__button"
          >
            <i class="fa-light fa-shapes"></i>
            <span className="render-group__button-text">Convert to Shape</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "separateMasks",
              )
            }
            className="render-group__button"
          >
            <i class="fa-light fa-object-ungroup"></i>
            <span className="render-group__button-text">Separate Masks</span>
          </div>
          </div>
      </div>
    </>
  );
}

export default MaskGroup;
