import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";

function ColorControls({selectedTag, buttonTags, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible('ColorControlsVisibility');

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
          onClick={() => setIsVisible(!isVisible)}
          className={`button-card resolution-toggle ${isVisible ? 'active' : ''}`}
      >
          <div className="button-card__icon">
          <i class="fa-solid fa-swatchbook"></i>
        <div />
      </div>
      <div className="button-card__title">Color Controls</div>
      {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? '' : 'hidden'}`}>

      <div
        onClick={() => handleClick("ae2ppColorControls", {type:'layer'})}
        className="resolution-tools__button"
      >
        <i class="fa-solid fa-fill-drip"></i><span>Selected Layer</span>
      </div>
      <div
        onClick={() => handleClick("ae2ppColorControls", {type:'selected comp'})}
        className="resolution-tools__button"
      >
        <i class="fa-solid fa-images"></i><span>Selected Comp</span>
      </div>
      <div
        onClick={() => handleClick("ae2ppColorControls", {matte:true})}
        className="resolution-tools__button"
      >
        <i class="fa-regular fa-circle-three-quarters-stroke"></i><span>Create Matte</span>
      </div>
      <div
        onClick={() => handleClick("ppGradientColor", true)}
        className="resolution-tools__button"
      >
        <i class="fa-solid fa-swatchbook"></i><span>Gradient Controls</span>
      </div>
    </div>
    </>
  );
}

export default ColorControls;
