import React, { useState, useEffect } from "react";
import Preloader from "../Preloader/Preloader.jsx";
import { USER_FOLDER } from "../../utils/constants";

const LoadData = ({ onDataLoaded, isOpen, lastHash, setLastHash }) => {
  const [loading, setLoading] = useState(true);
  const saveToken = (path) => {
    const token = localStorage.getItem("jwt");
    if (token) {
      const saveResult = window.cep.fs.writeFile(
        path,
        token,
        window.cep.encoding.UTF8,
      );
      if (saveResult.err === 0) {
        console.log("Файл успешно сохранен:", path);
      } else {
        alert("Error saving file:", saveResult.err);
      }
    }
  };

  useEffect(() => {
    setLoading(true);

    window.jsx.evalScript(
      "@JSXBIN@ES@2.0@MyBbyBnABMAbyBn0AJJBnASzKjBjVjUjIjPjSiOjBjNjFBAneJiIjBjSjDjIjFjOjLjPftJCnASzLjQjSjPjKjFjDjUiOjBjNjFCBneNiBiFhShAiFjYjUjFjOjTjJjPjOftJEnASzMjTjDjSjJjQjUiGjPjMjEjFjSDCEjzGiGjPjMjEjFjSEfRBCzBhLFCFXzGjGjTiOjBjNjFGfXzIjVjTjFjSiEjBjUjBHfjEfnneBhPVBfAnnftnftOFJFnAEXzGjDjSjFjBjUjFIfVDfCnfAhzBhBJVDfCnJHnASzLjGjJjMjFjTiGjPjMjEjFjSKDEjEfRBCFCFXGfVDfCnneBhPVCfBnnftnftOIJInAEXIfVKfDnfAVDfCnJKnASzOjFjYjUjFjOjTjUjJjPjOiEjBjUjBLEVzGjTjUjSjJjOjHMfInftcMnAVzIjTjPjGjUjXjBjSjFNfJCRBFeCjBjFfRBFeCjQjQfCbOn0ACJOnASNJneHjBjFhSjEjBjUjBffDPnAzAOtbSn0ACJSnASNJneGjQjQiEjBjUjBffDTnAOtOWbXn0ACJXnASzOjFjYjUjFjOjTjUjJjPjOiGjJjMjFPFEjzEiGjJjMjFQfRBCFCFCFXGfXHfjEfnneZhPiIjBjSjDjIjFjOjLjPhPiBiFhShAiFjYjUjFjOjTjJjPjOhPVNfJnnnneEhOjKjTjYftnftOgbbgcn0AEJgcnAEXzEjPjQjFjORfVPfFRDFeBjSFeEiUiFiYiUFeEhfhfhfhfffJgdnASzKjHjFjUiDjPjOjUjFjOjUSGEXzEjSjFjBjETfVPfFnfnftJgenAEXzFjDjMjPjTjFUfVPfFnfOhAnACzDhdhdhdVVSfGVLfEnnbhCn0AFJhCnASzLjDjSjFjBjUjFjEiGjJjMjFWHEjQfRBCFCFCFXGfXHfjEfnneZhPiIjBjSjDjIjFjOjLjPhPiBiFhShAiFjYjUjFjOjTjJjPjOhPVNfJnnnneEhOjKjTjYftnftJhInABXzIjFjOjDjPjEjJjOjHXfVWfHneFiViUiGhNhYfJhJnAEXRfVWfHRBFeBjXffJhKnAEXzFjXjSjJjUjFYfVWfHRBVLfEffJhLnAEXUfVWfHnfAXzGjFjYjJjTjUjTZfVPfFbhOn0AFJhOnASWHEjQfRBCFCFCFXGfXHfjEfnneZhPiIjBjSjDjIjFjOjLjPhPiBiFhShAiFjYjUjFjOjTjJjPjOhPVNfJnnnneEhOjKjTjYftnftJhRnABXXfVWfHneFiViUiGhNhYfJhSnAEXRfVWfHRBFeBjXffJhTnAEXYfVWfHRBVLfEffJhUnAEXUfVWfHnfACzChBhdgaVNfJjzJjVjOjEjFjGjJjOjFjEgbfnnnAKN4B0AhAB40BiAC4B0AiAD4C0AiAK4D0AiAL4E0AiAP4F0AiAM40BhAS4G0AiAW4H0AiACIAzHjHjFjUiEjBjUjBgcAhX0EOByB",
    );
    //   const getExtension = async () => {
    //     const versionUpdate = new Date().getTime();
    //     try {
    //         const response = await fetch(`./scripts/ae2data.js?ver=${versionUpdate}`);
    //         const fetchedData = await response.text();
    //         window.jsx.evalScript(`getData(${JSON.stringify(fetchedData)}, "ae")`);
    //         onDataLoaded();
    //     } catch (error) {
    //         console.error("Ошибка при получении данных:", error);
    //     }
    //     setLoading(false);
    // };

    const getExtension = async () => {
      try {
        // Предполагаем, что имя файла может изменяться, включая хеш.
        const scriptName = "ae2data"; // Базовое имя скрипта без хеша
        const scripts = await fetch(
          `./scripts/manifest.json?${new Date().getTime()}`,
        );
        const scriptsManifest = await scripts.json();
        const hashedScriptName = scriptsManifest[scriptName]; // Получаем актуальное имя файла с хешем
        const currentHash = hashedScriptName.split("_")[1]; // Извлекаем хеш из имени файла
        if (currentHash !== lastHash) {
          // Продолжаем только если хеш изменился
          const response = await fetch(`./scripts/${hashedScriptName}.js`);
          const fetchedData = await response.text();
          window.jsx.evalScript(
            `getData(${JSON.stringify(fetchedData)}, "ae")`,
          );
          localStorage.setItem("lastHash", lastHash);
          setLastHash(currentHash); // Обновляем последний известный хеш
        }
      } catch (error) {
        alert("Ошибка при получении данных:", error);
        console.error("Ошибка при получении данных:", error);
        setLoading(false); // Завершение загрузки, независимо от результата
        onDataLoaded(); // Сигнализируем родительскому компоненту о завершении загрузки
        window.location.reload();
      } finally {
        setLoading(false); // Завершение загрузки, независимо от результата
        onDataLoaded(); // Сигнализируем родительскому компоненту о завершении загрузки
      }
    };

    getExtension();
    const path = `${USER_FOLDER}/Harchenko/AE2DR/token`;
    saveToken(path);
  }, [onDataLoaded]);

  useEffect(() => {
    const getFiles = async () => {
      try {
        window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
        window.jsx.evalScript(
          "run($.ae.userFolder('AE2AE'));",
          async function (result) {
            if (result) {
              const data = JSON.parse(result);

              const requiredFiles = [
                "Double click here for help.jpg",
                "ma watermark.mp3",
                "preview watermark.mp3",
                "drop zone.tiff",
                "placeholder.png",
                "Placeholder.mp4",
                "After Effects Template Instructions.pdf",
                "Premiere Pro Template Instructions.pdf",
                "Motion Graphics Template Instructions.pdf",
                "Final Cut Pro Template Instructions.pdf",
                "Davinci Resolve Template Instructions.pdf",
                "Davinci Resolve Macros Instructions.pdf",
              ];
              const fetchAndWriteFile = async (filename, path) => {
                try {
                  const response = await fetch(`./files/${filename}`);
                  if (response.ok) {
                    // Используем Blob для бинарных данных
                    const blob = await response.blob();
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function () {
                      const base64data = reader.result;
                      const base64DataOnly = base64data.split(",")[1]; // Удалить часть data:url
                      const writeResult = window.cep.fs.writeFile(
                        `${path}/${filename}`,
                        base64DataOnly,
                        window.cep.encoding.Base64,
                      );
                      if (writeResult.err === 0) {
                        //alert("File written successfully:", filename);
                      } else {
                        alert("Error writing file:", filename);
                      }
                    };
                  } else {
                    alert("Failed to fetch file:", filename);
                  }
                } catch (error) {
                  //alert("An error occurred:", error);
                }
              };

              // Проверяем, есть ли у нас необходимые файлы в массиве, и загружаем отсутствующие
              for (let requiredFile of requiredFiles) {
                console.log(`File ${requiredFile} is missing, fetching...`);
                await fetchAndWriteFile(requiredFile, data.path);
              }
            }
          },
        );
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };
    getFiles();
  }, [lastHash]);

  // Если мы не находимся в режиме production, возвращаем null
  if (process.env.NODE_ENV !== "production") {
    return null;
  }

  return loading ? <Preloader isOpen={isOpen} /> : null;
};

export default LoadData;
