import { BASE_URL } from "./constants.js";

class Api {
  constructor({ baseUrl }) {
    this._baseUrl = baseUrl;
    this._headers = {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };
  }

  _fetchWithCheck(endpoint, config = {}) {
    return fetch(`${this._baseUrl}/${endpoint}`, {
      ...config,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("jwt")}`,
        ...config.headers,
      },
    }).then((response) => {
      return response.json().then((data) => {
        if (!response.ok) {
          throw new Error(data.message);
        }
        return data;
      });
    });
  }

  getFonts(type) {
    return this._fetchWithCheck('fonts', {
      method: 'POST', // Убедитесь, что сервер ожидает POST-запрос
      body: JSON.stringify(type),
    });
  }

  getFootages(mediaArray) {
    return this._fetchWithCheck('footages', {
      method: 'POST',
      body: JSON.stringify(mediaArray),
    });
  }

  createUser({ name, family, login, email, password, telegram }) {
    return this._fetchWithCheck('signup', {
      method: 'POST',
      body: JSON.stringify({
        name,
        family,
        login,
        email,
        password,
        telegram // Предполагается, что telegram уже в формате { username: "user_name" }
      }),
    });
  }

  login({ email, password }) {
    return this._fetchWithCheck("signin", {
      method: "POST",
      body: JSON.stringify({ email, password }),
    });
  }

  getUserInfo() {
    return this._fetchWithCheck("users/me");
  }

  updateUser(userId, data) {
    return this._fetchWithCheck(`users/${userId}`, {
      method: "PATCH",
      body: JSON.stringify(data),
    });
  }

  getButtons() {
    return this._fetchWithCheck("buttons");
  }
}

const mainApi = new Api({
  baseUrl: BASE_URL,
});

export default mainApi;
