import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";

function AutoCameraGroup({ selectedTag, buttonTags, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("AutoCameraGroupVisibility");
  const [animationType, setAnimationType] = useVisible('autoCameraAnimationType');

  const handleCheckboxChange = (option) => {
    if (animationType === option) {
      setAnimationType(false); // Снимаем выбор, если текущий чекбокс уже выбран
    } else {
      setAnimationType(option);
    }
  };

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i className="fa-sharp fa-regular fa-camera-movie"></i>
        </div>
        <div className="button-card__title">Auto Camera</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">

          {/* Checkbox for Random */}
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="random-auto-camera"
              checked={animationType === 'random'}
              onChange={() => handleCheckboxChange('random')}
              className="hidden-checkbox"
            />
            <label htmlFor="random-auto-camera">
              <span className="custom-checkbox"></span>
              <span className="label-text">Random</span>
            </label>
          </div>

          {/* Checkbox for Step */}
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="step-auto-camera"
              checked={animationType === 'step'}
              onChange={() => handleCheckboxChange('step')}
              className="hidden-checkbox"
            />
            <label htmlFor="step-auto-camera">
              <span className="custom-checkbox"></span>
              <span className="label-text">Step</span>
            </label>
          </div>
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() => {
              handleClick(
                "ae2AutoCamera",
                {animationType: animationType}
              );
            }}
            className="render-group__button"
          >
            <i className="fa-sharp fa-regular fa-camera-movie"></i>
            <span className="render-group__button-text">Create Camera</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default AutoCameraGroup;
