import useVisible from "../../hooks/useVisible.jsx";

function ShapeGroupAnchor({ selectedTag, buttonTags, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("ShapeGroupAnchorVisibility");

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}('${argument}'));`, function (result) {});
  };

  const iconsConfig = [
    { arg: "top-left", iconClass: "fa-light fa-arrow-up-left" },
    { arg: "top", iconClass: "fa-light fa-arrow-up" },
    { arg: "top-right", iconClass: "fa-light fa-arrow-up-right" },

    { arg: "left", iconClass: "fa-light fa-arrow-left" },
    { arg: "center", iconClass: "fa-sharp fa-regular fa-plus" },
    { arg: "right", iconClass: "fa-light fa-arrow-right" },

    {
      arg: "bottom-left",
      iconClass: "fa-light fa-arrow-up-left fa-flip-vertical",
    },
    { arg: "bottom", iconClass: "fa-light fa-arrow-up fa-flip-vertical" },
    {
      arg: "bottom-right",
      iconClass: "fa-light fa-arrow-up-right fa-flip-vertical",
    },
  ];

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-sharp fa-regular fa-circle-dot"></i>
        </div>
        <div className="button-card__title">Shape Group Anchor</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="anchorAlign__iconsGrid">
          {iconsConfig.map((icon, index) => (
            <div
              key={index}
              className="anchorAlignContainer__icon"
              onClick={() => handleClick("shapeGroupAnchor", icon.arg)}
            >
              <i className={icon.iconClass}></i>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default ShapeGroupAnchor;
