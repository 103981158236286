import useVisible from "../../hooks/useVisible.jsx";
import React, { useState, useEffect } from "react";

function AutoColorsScheme({ selectedTag, buttonTags, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("AutoColorsSchemeVisibility");
  const [controlLayer, setControlLayer] = useVisible("AutoColorsSchemeControlLayer");
  const [expression, setExpression] = useVisible("AutoColorsSchemeExpression");
  const [skipShape, setSkipShape] = useVisible("AutoColorsSchemeSkipShape");
  const [layerID, setLayerID] = useVisible("AutoColorsSchemeControlID");
  const [compName, setCompName] = useVisible("AutoColorsSchemeControlCompName");

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {
      if (result && result !== "undefined" && script === "$.ae.layerID") {
        result = result === "false" ? (result = false) : JSON.parse(result);
        setLayerID(result.id);
        setCompName(result.compName);
      }
    });
  };

  useEffect(() => {
    if (layerID) {
      setControlLayer(false);
    }
  }, [layerID]);

  useEffect(() => {
    if (controlLayer) {
      setLayerID(false);
    }
  }, [controlLayer]);

  if (selectedTag && !buttonTags.includes(selectedTag)) {
  return null;
}

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-regular fa-palette"></i>
        </div>
        <div className="button-card__title">Auto Colors Scheme</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
        <div className={`render-group__checkbox ${layerID ? 'hidden' : ''}`}>
            <input
              type="checkbox"
              id="autoColorsSchemeControlLayer"
              checked={controlLayer}
              onChange={() => setControlLayer(!controlLayer)}
              className="hidden-checkbox"
            />
            <label htmlFor="autoColorsSchemeControlLayer">
              <span className="custom-checkbox"></span>
              <span className="label-text">Add Control Layer</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="autoColorsSchemeExpression"
              checked={expression}
              onChange={() => setExpression(!expression)}
              className="hidden-checkbox"
            />
            <label htmlFor="autoColorsSchemeExpression">
              <span className="custom-checkbox"></span>
              <span className="label-text">Add Expression</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="autoColorsSchemeSkipShape"
              checked={skipShape}
              onChange={() => setSkipShape(!skipShape)}
              className="hidden-checkbox"
            />
            <label htmlFor="autoColorsSchemeSkipShape">
              <span className="custom-checkbox"></span>
              <span className="label-text">Skip Shape</span>
            </label>
          </div>
        </div>

        <div className={`set-button ${controlLayer ? 'hidden' : ''}`}>
          <div
            onClick={() => handleClick("$.ae.layerID", false)}
            className={`render-group__button ${layerID ? "set-button-active exp" : ""}`}
          >
            {layerID ? (
              <div>
                {compName}
              </div>
            ) : (
              <>
                <i className="fa-regular fa-circle-a"></i>
                <span className="render-group__button-text">
                  Set Control Layer
                </span>
              </>
            )}
          </div>
        </div>

        <div className="videobolt-tools">
        <div
            onClick={() =>
              handleClick(
                "autoColorsScheme",
                JSON.stringify({ type: "layer", controlLayer: controlLayer, expression: expression, skipShape:skipShape, notification: true, layerID: layerID })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-light fa-layer-group"></i>
          <span>Selected Layers</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "autoColorsScheme",
                JSON.stringify({ type: "comp", controlLayer: controlLayer, expression: expression, skipShape:skipShape, notification: true, layerID: layerID })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-image-polaroid"></i>
          <span>Active Comp</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "autoColorsScheme",
                JSON.stringify({ type: "selected comp", controlLayer: controlLayer, expression: expression, skipShape:skipShape, notification: true, layerID: layerID })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-images"></i>
          <span>Selected Comp</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "autoColorsScheme",
                JSON.stringify({ type: "project", controlLayer: controlLayer, expression: expression, skipShape:skipShape, notification: true, layerID: layerID })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-images"></i>
          <span>Project</span>
          </div>

        </div>
      </div>
    </>
  );
}

export default AutoColorsScheme;
