const BASE_URL = "https://api.ae2authors.net";
const CATEGORIES = ["ae", "pp", "mg", "am", "dr", "gt"];
const USER_FOLDER = (() => {
  var path, isMac;
  if (process.env.NODE_ENV !== "production") {
    return;
  }
  path = decodeURI(window.__adobe_cep__.getSystemPath("userData"));
  isMac = navigator.platform[0] === "M"; // [M]ac
  path = path.replace("file://" + (isMac ? "" : "/"), "");
  return path;
})();
export { BASE_URL, CATEGORIES, USER_FOLDER };
